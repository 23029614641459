<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-11-04 16:57:17
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-14 11:13:47
 * @FilePath: /mediatom-web/src/viewsForManage/dashboard/models/RealTimeData/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-spin :spinning="spinning" wrapperClassName="spin-wrapper">
    <div class="real-time-data-container">
      <HomeTitile title="实时数据" class="title" />
      <div class="row-list">
        <div class="row-head">
          <div class="name">名称</div>
          <div class="value">数据</div>
          <div class="ratio">环比</div>
        </div>
        <div class="list-wrapper">
          <div class="row-item" v-for="(item, index) in dataList" :key="index">
            <div class="name">
              <div class="box" :style="{'background-color': item.color}"></div>
              <span>{{ item.name }}</span>
            </div>
            <div class="value">
              <template v-if="isMoney(item.prop)">{{ moneyText }}</template>
              <count-to :start-val="0" :decimals="item.decimals" :end-val="item.value"/><span v-if="isRota(item.prop)">%</span>
            </div>
            <div class="ratio" :class="{[getRatioColor(item.ratio)]: true}">
              <UpSvg class="icon" v-if="item.ratio > 0"/>
              <DownSvg class="icon" v-else-if="item.ratio < 0"/>
              <KeepSvg class="icon" v-else/>
              <span>{{ Math.abs(item.ratio || 0) }}%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import HomeTitile from '../../components/homeTitle.vue'
import { todayData } from '@/apiForManage/dashboard'
import { valueList } from './list'
import mixRateAndMoneyMethod from '@/mixins/rateAndMoneyMethod'
import CountTo from 'vue-count-to'
import UpSvg from './svgs/up.svg?inline'
import DownSvg from './svgs/down.svg?inline'
import KeepSvg from './svgs/keep.svg?inline'
export default {
  name: 'RealTimeData',
  mixins: [mixRateAndMoneyMethod],
  components: {
    HomeTitile,
    CountTo,
    UpSvg,
    DownSvg,
    KeepSvg
  },
  data () {
    return {
      dataList: [],
      isLoading: false
    }
  },
  created () {
    this.dealData([])
    this.getData()
  },
  methods: {
    async getData () {
      this.spinning = true
      const { data = {} } = await todayData({})
      this.dealData(data)
      this.spinning = false
    },
    dealData (data) {
      this.dataList = valueList.map((item) => {
        return {
          name: item.name,
          value: data[item.value],
          ratio: data[item.ratio],
          prop: item.value,
          decimals: item.decimals,
          color: item.color
        }
      })
    },
    getRatioColor (ratio) {
      if (ratio > 0) {
        return 'up'
      } else if (ratio < 0) {
        return 'down'
      } else {
        return 'keep'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.real-time-data-container {
  padding: @smallMargin 0 8px 0;
  height: 100%;
  color: #5A607F;;
  display: flex;
  flex-direction: column;
  .title{
    padding-left: @smallMargin;
  }
  .row-list {
    font-size: calc(13em / 14);
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    .row-head {
      height: calc(38em / 14);
      line-height: calc(38em / 14);
      width: 100%;
      background-color: #faf8ff;
      display: flex;
      font-size: 1em;
      font-weight: 500;
    }
    .row-head, .list-wrapper{
      padding: 0 @smallMargin;
    }
    .row-head,
    .row-item {
      .name {
        width: 40%;
        text-align: left;
      }
      .value {
        flex: 1 auto;
        text-align: left;
      }
      .ratio {
        width: 23%;
        text-align: left;
        display: flex;
        align-items: center;
        font-weight: 500;
        &.up{
          color: #EF4770;
        }
        &.down{
          color: #38CB89;
        }
        &.keep{
          color: #FFAB00;
        }
        .icon{
          width: calc(14em / 14);
          height: calc(14em / 14);
          margin-right: calc(10em / 14);
        }
      }
    }
    .row-item {
      display: flex;
      flex: 1 auto;
      line-height: 100%;
      align-items: center;
      .value {
        font-weight: 500;
      }
    }
  }
  .list-wrapper {
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    .row-item {
      border-bottom: 1px solid rgba(21, 34, 50, 0.15);
      &:last-child {
        border: none;
      }
      .name{
      position: relative;
      display: flex;
      align-items: center;
      .box{
        height: calc(12em / 14);
        width: calc(12em / 14);
        border-radius: 3px;
        margin-right: calc(7em / 14);
      }
    }}
  }
}
.spin-wrapper{
  height: 100%;
  ::v-deep{
    .ant-spin-container{
      height: 100%;
    }
  }
}
</style>
